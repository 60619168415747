<template>
    <v-container>
        <h2 class="primary--text mb-1" style="font-weight:400;">Applications</h2>
        <v-row>
            <v-col cols="4" >
                <v-card outlined elevation="2" style="text-align: center;" @click="leaveClick">
                    <v-icon color="#002060" style="font-size: 50px !important; padding-top: 10px;">mdi-account-outline</v-icon>
                    <p style="color: #002060; font-size:21px;margin-bottom: 0px;">Leave</p>
                </v-card>
            </v-col>
            <v-col cols="4" >
                <v-card outlined elevation="2" style="text-align: center;">
                    <v-icon color="#002060" style="font-size: 50px !important; padding-top: 10px;">mdi-border-all-variant</v-icon>
                    <p style="color: #002060; font-size:21px;margin-bottom: 0px;">Ticket</p>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
export default {
    name: "Apps",
    data: () => ({
        user_p : JSON.parse(localStorage.getItem('user'))
    }),
    methods:{
        leaveClick() {
            this.$router.push('/leave')
        }
    }
}
</script>